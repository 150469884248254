import React from "react"
import Message from "elements/Message"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"
import FileThumbnail from "./FileThumbnail"

const UploadDocumentsSummary = ({ route, documents, t, epharmacyState }) => {
  const { prescriptionDate } = epharmacyState
  const prescriptionDateStr = `${prescriptionDate?.month?.value} ${prescriptionDate?.date?.value} ${prescriptionDate?.year?.value}`
  return (
    <Section
      title={`Documents Uploaded`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
      isSectionRequired={true}
    >
      {documents?.length > 0 ? (
        <div className="mx-1">
          <div className="columns is-vcentered is-multiline">
            {documents?.map((document) => {
              const arr = document?.oldname?.split(".")
              const filetype = arr[arr.length - 1]
              const isPdf = filetype === "pdf"
              return (
                <div className="column is-6">
                  <FileThumbnail file={document} isPdf={isPdf} />
                </div>
              )
            })}
          </div>
          <p>
            <b>Prescription Date:</b> {prescriptionDateStr}
          </p>
        </div>
      ) : (
        <Message color="warning">
          <p className="has-text-black is-size-6">
            {"You have not uploaded any documents."}
          </p>
        </Message>
      )}
    </Section>
  )
}

export default UploadDocumentsSummary
