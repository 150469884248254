import axios from "axios"
import moment from "moment"
import { get } from "lodash"
import { navigate } from "gatsby"
import classNames from "classnames"
import React, { useContext, useState } from "react"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import SummaryConsent from "./SummaryConsent"
import EditDetailsButton from "elements/EditDetailsButton"
import MedicinesOrderedSummary from "./Summary/MedicinesOrderedSummary"
import UploadDocumentsSummary from "./Summary/UploadDocumentsSummary"

import { EpharmacyContext } from "./EpharmacyContext/EpharmacyContext"
import { orderSmsContent } from "./Summary/utils/orderRequestSmsContent"
import {
  generateRequestTemplate,
  zendeskApi,
} from "../../services/zendeskService"
import { orderRequestZendeskTemplate } from "./Summary/utils/orderRequestZendeskTemplate"

import styles from "./utils/epharmacy.module.scss"

import {
  GATSBY_SUBMIT_ORDER_FORM_WEBHOOK,
  GATSBY_ZENDESK_EMAIL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_SUBDOMAIN,
  GATSBY_TELERIVET_URL,
  GATSBY_TELERIVET_API_KEY,
  GATSBY_TELERIVET_ROUTE_ID,
  GATSBY_TELERIVET_PROJECT_ID,
  GATSBY_ENV,
} from "gatsby-env-variables"
import {
  generateUploadFiles,
  zendeskUploadFiles,
} from "../../services/zendeskService"
import { getSignedInUser } from "../Auth/services/user"
import {
  createAddressDocument,
  updateAddressDocument,
  updateUserDocument,
} from "./services/user"
import {
  accumulateQuantity,
  accumulatePrice,
  formatPrice,
} from "../Epharmacy/services/computations"
import { isBrowser } from "../../services/general"
import { addWorkingDays } from "../../services/date"
import { DELIVERY_FEE } from "./services/constants"

let { parseFormField } = require("../../services/airtable")

const Summary = ({ pageContext }) => {
  let userData = getSignedInUser()?.userData

  const [loading, setLoading] = useState(false)
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const { module } = pageContext

  let mgcareZendeskConfig = {
    email: GATSBY_ZENDESK_EMAIL,
    apiKey: GATSBY_ZENDESK_API_KEY,
    apiUrl: GATSBY_ZENDESK_SUBDOMAIN,
  }

  let sectionSummaryFields = parseFormField(
    pageContext.summaryFields.map((formField) => ({
      ...formField,
      section: formField.section,
    }))
  )
  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  let earliestDeliveryDate = moment()
  let sameDayDeliveryCutoff = moment()?.hour(8)?.minute(0)?.second(0)
  let pmShiftCutoff = moment()?.hour(14)?.minute(30)?.second(0)
  let additionalDeliveryDateDays = 0

  if (!!earliestDeliveryDate?.isAfter(sameDayDeliveryCutoff))
    additionalDeliveryDateDays = 1
  if (epharmacyState?.deliveryAddress?.addressType === "Office")
    additionalDeliveryDateDays = !!earliestDeliveryDate.isAfter(pmShiftCutoff)
      ? 2
      : 1

  earliestDeliveryDate = addWorkingDays({
    days: additionalDeliveryDateDays,
  })
  earliestDeliveryDate = earliestDeliveryDate.format("YYYY-MM-DD")
  let currentDeliveryDate = moment(
    `${epharmacyState?.preferredDeliveryDate?.month?.value} ${epharmacyState?.preferredDeliveryDate?.date?.value} ${epharmacyState?.preferredDeliveryDate?.year?.value}`
  )

  const handleSuccessCallback = () => {
    navigate(pageContext.nextPath)
    setLoading(false)
  }

  const handleErrorCallback = () => {
    navigate(pageContext.nextPath)
    setLoading(false)
  }

  const handleSubmit = async (epharmacyState) => {
    setLoading(true)

    let hasUploadedValidID = false
    for (let i = 0; i < epharmacyState?.documents?.length; i++) {
      let name = epharmacyState?.documents[i]?.name
      name = name.substring(0, 2)
      if (name == "id") {
        hasUploadedValidID = true
        break
      }
    }

    if (userData) {
      let userAddresses = getSignedInUser()?.addresses
      let addressList = userAddresses?.addresses || []
      addressList = addressList?.filter(
        (address) => address?.province === "Metro Manila"
      )
      let numberOfTimesHasUploadedID = userData?.numberOfTimesHasUploadedID

      if (!userData?.addresses) {
        let addressResponse = await createAddressDocument({
          values: epharmacyState,
        })
        let addressesUid = addressResponse.id

        await updateUserDocument({
          user: {
            ...userData,
            email: userData?.email || epharmacyState?.email,
          },
          addressesUid,
          hasUploadedValidID,
          numberOfTimesHasUploadedID,
        })

        if (isBrowser()) {
          sessionStorage.setItem(
            "userData",
            JSON.stringify({
              ...userData,
              email: userData?.email || epharmacyState?.email,
              addresses: addressesUid,
            })
          )
        }
      } else if (addressList?.length === 0) {
        await updateAddressDocument({
          values: epharmacyState,
          addressesUid: userData?.addresses,
          oldAddresses: userAddresses?.addresses,
        })
      } else {
        await updateUserDocument({
          user: {
            ...userData,
            email: userData?.email || epharmacyState?.email,
          },
          hasUploadedValidID,
          numberOfTimesHasUploadedID,
        })
        if (isBrowser()) {
          sessionStorage.setItem(
            "userData",
            JSON.stringify({
              ...userData,
              email: userData?.email || epharmacyState?.email,
            })
          )
        }
      }
    }

    const uploadedFiles = await zendeskUploadFiles(
      epharmacyState?.documents,
      null,
      mgcareZendeskConfig
    )

    const generatedFileToken = await generateUploadFiles(uploadedFiles)

    const parseMedicines = (medicines) => {
      let medicinesBody = ``
      medicines.forEach((medicine) => {
        let assistanceMeds = ""
        let price = medicine?.price_list?.unitPrice.toFixed(2)

        if (medicine.assistanceQuantity > 0) {
          assistanceMeds = medicine?.productTitle?.replace(
            ")",
            " - Medicine Assistance)"
          )
          medicinesBody += `\n${medicine?.productTitle} #${medicine?.qty}, Price: ₱${price}`
          medicinesBody += `NEWLINE${assistanceMeds} #${medicine?.assistanceQuantity}, Price: ₱ 0.00`
        } else {
          medicinesBody += `\n${medicine?.productTitle} #${medicine?.qty}, Price: ₱${price}`
        }
      })
      return medicinesBody
    }

    let medicinesBody = parseMedicines(epharmacyState?.medicines)

    const {
      totalQuantity,
      purchaseQuantity,
      assistanceQuantity,
    } = accumulateQuantity(epharmacyState?.medicines)

    const orderTotal = formatPrice({
      priceString: (
        accumulatePrice(epharmacyState?.medicines) + DELIVERY_FEE
      ).toFixed(2),
    })

    const parsedChangeFor =
      epharmacyState?.paymentOption === "Cash on Delivery" &&
      !!epharmacyState?.changeFor
        ? formatPrice({
            priceString: epharmacyState?.changeFor?.toFixed(2),
          })
        : "N/A"

    let updatedDeliveryDate = epharmacyState?.preferredDeliveryDate

    if (currentDeliveryDate.isBefore(moment(earliestDeliveryDate))) {
      updatedDeliveryDate = {
        month: {
          label: moment(earliestDeliveryDate).format("MMM"),
          value: moment(earliestDeliveryDate).format("MMM"),
        },
        date: {
          label: moment(earliestDeliveryDate).format("DD"),
          value: moment(earliestDeliveryDate).format("DD"),
        },
        year: {
          label: moment(earliestDeliveryDate).format("YYYY"),
          value: moment(earliestDeliveryDate).format("YYYY"),
        },
      }
    }

    try {
      const { firstName, lastName, email, mobileNumber } = epharmacyState

      // Adds a test tag if not in production
      let zendeskTags = ["order", "pulsecare", GATSBY_ENV]
      if (GATSBY_ENV !== "production") zendeskTags = [...zendeskTags, "test"]

      // Create a new Zendesk ticket
      const requestTemplate = await generateRequestTemplate({
        subject: `PCP Order from ${firstName} ${lastName}`,
        email: email,
        template: orderRequestZendeskTemplate,
        templateObjects: epharmacyState,
        tags: zendeskTags,
        uploadTokens: generatedFileToken,
      })

      const zendeskResponse = await zendeskApi({ ...mgcareZendeskConfig }).post(
        "/requests.json",
        requestTemplate
      )

      const generatedZendeskId = zendeskResponse?.data?.request?.id

      // Send an SMS via Telerivet
      const telerivetMessageBody = {
        content: orderSmsContent(generatedZendeskId),
        to_number: `+63${mobileNumber}`,
        route_id: GATSBY_TELERIVET_ROUTE_ID,
      }

      await axios.post(
        GATSBY_TELERIVET_URL,
        { ...telerivetMessageBody },
        {
          headers: {
            "Content-Type": "application/json",
            api_key: GATSBY_TELERIVET_API_KEY,
            route_id: GATSBY_TELERIVET_ROUTE_ID,
            project_id: GATSBY_TELERIVET_PROJECT_ID,
          },
        }
      )

      setLoading(false)
      handleSuccessCallback()
    } catch (error) {
      await axios.post(GATSBY_SUBMIT_ORDER_FORM_WEBHOOK, {
        ...epharmacyState,
        preferredDeliveryDate: updatedDeliveryDate,
        medicinesBody,
        totalQuantity,
        purchaseQuantity,
        assistanceQuantity,
        orderTotal,
        parsedChangeFor,
        files: generatedFileToken,
      })

      handleErrorCallback()
    }
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      isPrivate={pageContext?.module?.isPrivate}
    >
      <Container isCentered desktop={6}>
        {sectionSummaryFields.map((section) => {
          return (
            <Section
              title={section?.section}
              addOns={{
                right: <EditDetailsButton route={section.link} />,
              }}
              isSectionRequired={section?.isSectionRequired}
            >
              <div className="table-container">
                <table class="table is-fullwidth is-size-5">
                  <tbody>
                    {section.fields
                      .sort((firstField, secondField) => {
                        return firstField.order - secondField.order
                      })
                      .map((field) => {
                        let finalValue = null
                        switch (field.type) {
                          case "schedule":
                            finalValue = get(epharmacyState, field.name)
                              ? `${
                                  get(epharmacyState, field.name)?.day?.label
                                }, ${
                                  get(epharmacyState, field.name)?.time?.label
                                }`
                              : ""
                            break
                          case "select":
                          case "hospital":
                            finalValue = get(epharmacyState, field.name)
                              ? get(epharmacyState, field.name)?.value ===
                                "Other"
                                ? get(epharmacyState, "otherHospital")
                                : get(epharmacyState, field.name)?.value
                              : ""
                            break
                          case "date":
                            finalValue = get(epharmacyState, field.name)
                              ? `${
                                  get(epharmacyState, field.name)?.month?.value
                                } ${
                                  get(epharmacyState, field.name)?.date?.value
                                } ${
                                  get(epharmacyState, field.name)?.year?.value
                                }`
                              : ""
                            break
                          case "address":
                            finalValue = get(epharmacyState, field.name)
                              ? `${
                                  get(epharmacyState, field.name)?.streetAddress
                                }, ${
                                  get(epharmacyState, field.name)?.province
                                    ?.value
                                }, ${
                                  get(epharmacyState, field.name)?.city?.value
                                }, ${
                                  get(epharmacyState, field.name)?.barangay
                                    ?.value
                                }`.trim("")
                              : ""
                            if (field.name === "clinicAddress")
                              finalValue = finalValue.slice(1, -1) //finalValue = finalValue.replaceAll(/[,]/g, "");
                            break
                          default:
                            finalValue = get(epharmacyState, field.name)
                            if (field.name === "otherHospital")
                              finalValue = null
                            if (field.isContactNumber)
                              finalValue = `+63${finalValue}`
                            break
                        }

                        if (!!finalValue)
                          return (
                            <tr>
                              <td className="has-text-weight-bold">
                                {field.label}
                              </td>
                              <td
                                className={classNames(
                                  styles["summary__tableData"]
                                )}
                              >
                                {finalValue}
                              </td>
                            </tr>
                          )
                        return null
                      })}
                  </tbody>
                </table>
              </div>
            </Section>
          )
        })}
        <MedicinesOrderedSummary
          route={`/${module?.name}/order`}
          medicines={epharmacyState?.medicines}
          epharmacyState={epharmacyState}
        />
        <UploadDocumentsSummary
          route={`/${module?.name}/upload`}
          documents={epharmacyState?.documents}
          epharmacyState={epharmacyState}
        />
        <Message color="warning">
          {
            "Our pharmacist will send a message verifying your order. Your order will be delivered within the next working day."
          }
        </Message>
        <SummaryConsent
          loading={loading}
          state={epharmacyState}
          pageContext={pageContext}
          handleSubmit={handleSubmit}
          dispatch={epharmacyDispatch}
        />
      </Container>
    </Layout>
  )
}

export default Summary
